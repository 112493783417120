/* Name Heading font */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#space-particles {
    height: 100vh;
    width: 100vw;
}

@layer components {
    .side-bar {
        @apply fixed z-20 w-screen px-6 text-white h-fit bg-navy;
    }

    .contact-input {
        @apply w-full px-3 py-3 text-sm md:text-base text-white placeholder-slate-300 bg-[#1E293B] border-0 rounded shadow outline-aqua focus:outline-2;
    }

    .skill-badge {
        @apply flex flex-col items-center justify-center w-16 h-16 mb-4 rounded-lg shadow-lg; 
    }
}


